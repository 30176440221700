import React from 'react';

import Layout from '../components/Layout';
import Card from 'react-bootstrap/Card';
import CardColumns from 'react-bootstrap/CardColumns';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import picFounder from '../assets/images/team/UdayProfilePic.jpg';


const SvatvaTeam = () => (
  <Layout>
    <div id="main">
      <div className="inner box">
        <header >
          <h3>
            Our Team
          </h3> <br />
          <article>
          <p>
            At Svatva, we’re not just consultants. Our team consists of well-trained, experienced professionals who are masters 
            in their fields such as creative, information architecture enabled, UX/UI design for different online channels, 
          </p>
          </article>
        </header>
        <CardColumns>
            <Card>
                <Card.Img variant="top" src={picFounder} />
                <Card.Title align="center"> UDAY BATHINI <br /> Founder, CTO</Card.Title>
                <Card.Body>
                    <Row>
                    <Col><a href="https://www.linkedin.com/in/udaykar-bathini/"><span className="icon style2 fa-linkedin-square"></span> </a></Col>
                    <Col><a href="https://github.com/udaystartup"><span className="icon style2 fa-github-square"></span> </a></Col>
                    <Col><a href="mailto:uday.svatva@gmail.com"><span className="icon style2 fa-envelope"></span> </a></Col>
                    <Col><a href= "https://twitter.com/udaykarb"><span className="icon style2 fa-twitter-square"></span> </a></Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card>
                <Card.Img variant="top" src={picFounder} />
                <Card.Title align="center"> UDAY BATHINI <br /> Founder, CTO</Card.Title>
                <Card.Body>
                    <Row>
                    <Col><a href="https://www.linkedin.com/in/udaykar-bathini/"><span className="icon style2 fa-linkedin-square"></span> </a></Col>
                    <Col><a href="https://github.com/udaystartup"><span className="icon style2 fa-github-square"></span> </a></Col>
                    <Col><a href="mailto:uday.svatva@gmail.com"><span className="icon style2 fa-envelope"></span> </a></Col>
                    <Col><a href= "https://twitter.com/udaykarb"><span className="icon style2 fa-twitter-square"></span> </a></Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card>
                <Card.Img variant="top" src={picFounder} />
                <Card.Title align="center"> UDAY BATHINI <br /> Founder, CTO</Card.Title>
                <Card.Body>
                    <Row>
                    <Col><a href="https://www.linkedin.com/in/udaykar-bathini/"><span className="icon style2 fa-linkedin-square"></span> </a></Col>
                    <Col><a href="https://github.com/udaystartup"><span className="icon style2 fa-github-square"></span> </a></Col>
                    <Col><a href="mailto:uday.svatva@gmail.com"><span className="icon style2 fa-envelope"></span> </a></Col>
                    <Col><a href= "https://twitter.com/udaykarb"><span className="icon style2 fa-twitter-square"></span> </a></Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card>
                <Card.Img variant="top" src={picFounder} />
                <Card.Title align="center"> UDAY BATHINI <br /> Founder, CTO</Card.Title>
                <Card.Body>
                    <Row>
                    <Col><a href="https://www.linkedin.com/in/udaykar-bathini/"><span className="icon style2 fa-linkedin-square"></span> </a></Col>
                    <Col><a href="https://github.com/udaystartup"><span className="icon style2 fa-github-square"></span> </a></Col>
                    <Col><a href="mailto:uday.svatva@gmail.com"><span className="icon style2 fa-envelope"></span> </a></Col>
                    <Col><a href= "https://twitter.com/udaykarb"><span className="icon style2 fa-twitter-square"></span> </a></Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card>
                <Card.Img variant="top" src={picFounder} />
                <Card.Title align="center"> UDAY BATHINI <br /> Founder, CTO</Card.Title>
                <Card.Body>
                    <Row>
                    <Col><a href="https://www.linkedin.com/in/udaykar-bathini/"><span className="icon style2 fa-linkedin-square"></span> </a></Col>
                    <Col><a href="https://github.com/udaystartup"><span className="icon style2 fa-github-square"></span> </a></Col>
                    <Col><a href="mailto:uday.svatva@gmail.com"><span className="icon style2 fa-envelope"></span> </a></Col>
                    <Col><a href= "https://twitter.com/udaykarb"><span className="icon style2 fa-twitter-square"></span> </a></Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card>
                <Card.Img variant="top" src={picFounder} />
                <Card.Title align="center"> UDAY BATHINI <br /> Founder, CTO</Card.Title>
                <Card.Body>
                    <Row>
                    <Col><a href="https://www.linkedin.com/in/udaykar-bathini/"><span className="icon style2 fa-linkedin-square"></span> </a></Col>
                    <Col><a href="https://github.com/udaystartup"><span className="icon style2 fa-github-square"></span> </a></Col>
                    <Col><a href="mailto:uday.svatva@gmail.com"><span className="icon style2 fa-envelope"></span> </a></Col>
                    <Col><a href= "https://twitter.com/udaykarb"><span className="icon style2 fa-twitter-square"></span> </a></Col>
                    </Row>
                </Card.Body>
            </Card>
        </CardColumns>
      </div>
    </div>
  </Layout>
);

export default SvatvaTeam;